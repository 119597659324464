import React, { useEffect, useState } from 'react';
import './style.scss'

import axios from 'axios';
import store from 'store';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { _Object } from 'utils/interfaces';
import { closeModal, dateFormat, getSortIcon, handleFilters } from 'utils';
import { customersService } from 'services/customers.service';
import { AddEditCustomer, DeleteItem, OpenCustomerDetails } from 'views/components';
import { DeleteButton, EditButton, Plus } from '../assets/icons';
import { useSelector } from 'react-redux';
import moment from 'moment';
import DropdownFilter from 'views/components/form-inputs/dropdown-filter';

const Team = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [staffs, setStaffs] = useState<_Object>({});
	const [selectedStaff, setSelectedStaff] = useState<any>('');
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [previewUrl, setPreviewUrl] = useState('');
	const { loggedInUser } = useSelector((state: _Object) => state.session)

	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		'filters[role]': (loggedInUser?.role === 'admin' || loggedInUser?.role === 'receptionist') ? '' : loggedInUser?.role === 'manager' ? 'manager' : loggedInUser?.role === 'gems_manager' ? 'gems_manager' : 'astrologer',
		role: '',
		gender: 'male',
		name: '',
		email: '',
		phone_number: '',
		dob: '',
		birth_place: '',
		tob: '',
		sort: '-created_at'
	});

	const getCustomer = () => {
		setLoading(true)
		customersService.getCustomers(filterData).then((data: any) => {
			setStaffs({ ...data, items: data.items?.filter((item: _Object) => item.role !== 'customer' && item.role !== 'admin') })
			setLoading(false)
		})
	}

	useEffect(() => {
		getCustomer()
	}, [filterData])

	const deleteItem = () => {
		setLoading(true)
		customersService.deleteCustomers(selectedStaff).then((data: _Object) => {
			!data.error && getCustomer()
			setLoading(false)
			closeModal('DeleteStaff')
			toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
		})
		console.log(setFilterData);
	}

	const formik: any = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		validationSchema: Yup.object({
			name: Yup.string().required('Name is required'),
			email: Yup.string().email('Invalid email address').required('Email is required'),
			phone_number: Yup.string().required('Phone number is required'),
			dob: Yup.string().required('Date of Birth is required'),
			tob: Yup.string().required('Time of Birth is required'),
			birth_place: Yup.string().required('Birth Place is required'),
			gender: Yup.string().required('Gender is required'),
			status: Yup.string().required('Status is required'),
			role: Yup.string().required('Role is required')
		}),
		onSubmit: (values: _Object) => {
			setLoading(true)
			const { password, page, ...filteredValues } = values;
			delete filteredValues['filters[role]'];
			if (selectedStaff) {
				customersService.updateCustomers(selectedStaff, filteredValues).then((customer) => {
					if (!customer.error) {
						setLoading(false)
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(selectedStaff)
						}
						getCustomer()
					}
					console.log('c', password, page);
				})
			} else {
				customersService.createCustomers(filteredValues).then((customer) => {
					if (!customer.error) {
						setLoading(false)
						toast.success(customer.message, { style: { backgroundColor: '#b7e9bc' } })
						closeModal('exampleModal')
						if (selectedFile) {
							handleUpload(customer.user_id)
						}
						getCustomer()
					}
				})
			}
		}
	});

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUpload = async (id: string) => {
		if (selectedFile) {
			const data = new FormData();
			data.append('entity_id', id);
			data.append('files', selectedFile);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				response && getCustomer()
			} catch (error) {
				console.error('Error uploading image:', error);
			}
		}
	}

	const getCustomerDetail = (id: string) => {
		setLoading(true);
		customersService.getCustomerDetails(id).then((item: _Object) => {
			formik.setValues((prevValues: _Object) => ({
				...prevValues,
				...item
			}));
			setLoading(false);
		});
	};
	console.log('formik', formik);

	return (
		<>
			<section className="team-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Staffs</h6>
								<div>
									{filterData.filter && <button
										type="button"
										className="btn btn-primary me-3"
										onClick={() => {
											setFilterData({
												page: 1,
												sort: '-created_at'
											})
										}}
									>Clear All</button>}
									<button
										type="button"
										className="btn btn-primary"
										data-bs-toggle="modal" data-bs-target="#exampleModal"
										onClick={() => { formik.resetForm(), setSelectedStaff('') }}
									>
										<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
										Add New
									</button>
								</div>
							</div>

							<div className="card-body table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th style={{ width: '39px' }}>#</th>
											<th style={{ whiteSpace: 'nowrap', width: '107px' }}>
												<button
													className="btn p-0 border-0"
													onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
													Name
													{getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
												</button>
											</th>
											<th style={{ whiteSpace: 'nowrap', width: '260px' }}>Contact Info.</th>
											<th style={{ whiteSpace: 'nowrap', width: '140px' }}>Date of Birth</th>
											<th style={{ whiteSpace: 'nowrap', width: '140px' }}>Birth Place</th>
											<DropdownFilter
												title="Gender"
												options={[
													{ label: 'Male', value: 'male' },
													{ label: 'Female', value: 'female' }
												]}
												filterKey="gender"
												filterData={filterData}
												setFilterData={setFilterData}
											/>
											<th style={{ width: '109px' }}>Role</th>
											<DropdownFilter
												title="Status"
												options={[
													{ label: 'Active', value: 'active' },
													{ label: 'Close', value: 'close' }
												]}
												filterKey="status"
												filterData={filterData}
												setFilterData={setFilterData}
											/>
											<th className="action-button" style={{ width: '115px' }}>Action</th>
										</tr>
									</thead>
									<tbody>

										{loading ?
											(
												Array.from({ length: 5 }).map((_, index) => (
													<tr key={index}>
														{Array.from({ length: 9 }).map((_, cellIndex) => (
															<td className="placeholder-glow" key={cellIndex}>
																<span className="placeholder col-12"></span>
															</td>
														))}
													</tr>
												))
											) :
											staffs?.items?.length === 0 ?
												<tr>
													<td colSpan={12} className="text-center">
														No record found!
													</td>
												</tr>
												:

												staffs?.items?.map((item: any, i: number) => {
													if ((loggedInUser?.role === 'receptionist' && item?.role === 'admin') || (item?.role === 'customer')) {
														return null
													}
													return (
														<>
															<tr>
																<td>{i + 1}</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.name}
																	</p>
																</td>
																<td>
																	<a href={`mailto:${item?.email}`}>
																		<p className="mb-1">{item?.email}</p>
																	</a>
																	<a href={`tel:${item?.phone_number}`}>
																		<p className="mb-1">{item?.phone_number}</p>
																	</a>
																</td>
																<td>
																	{item?.dob && <p className="mb-1 text-capitalize">{dateFormat(item?.dob)}<br />  <span>{item?.tob ? moment(item?.tob, 'HH:mm').format('hh:mm A') : ''}</span>
																	</p>}
																</td>
																{item?.price && <td>
																	<p className="mb-1">
																		₹{item?.price}
																	</p>
																</td>}
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.birth_place}
																	</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.gender?.charAt(0)?.toUpperCase() + item?.gender?.slice(1)}
																	</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.role ? item?.role === 'gems_manager' ? 'Gems Manager' : item?.role : '-'}
																	</p>
																</td>

																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.status}
																	</p>
																</td>

																<td>
																	<ul className="list-inline">
																		<li className="list-inline-item chat-icon">
																			<a href={''} className="btn btn-success eye-edit p-0" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"
																				onClick={() => { getCustomerDetail(item?.id), setSelectedStaff(item?.id) }}>
																				<img src={EditButton} alt="Edit" width={22} height={20} />
																			</a>
																		</li>

																		<li className="list-inline-item">
																			<button type="button"
																				onClick={() => setSelectedStaff(item?.id)}
																				className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteStaff">
																				<img src={DeleteButton} alt="Delete" width={16} height={16} />
																			</button>
																		</li>
																	</ul>
																</td>
															</tr>
														</>
													)
												})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section>
			<AddEditCustomer formik={formik} previewUrl={previewUrl} handleImageChange={handleImageChange} selectedCustomers={selectedStaff} staff={true} loading={loading} />
			<DeleteItem title={'Staff'} modalId={'DeleteStaff'} deleteItem={deleteItem} />
			<OpenCustomerDetails formik={formik} loading={loading} />
		</>
	)
}

export default Team