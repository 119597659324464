import React, { useState } from 'react'
import { _Object } from 'utils/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'

const InputField = ({ name, type = 'text', onChange, value, label, placeholder = 'Enter here', required = false, className = '', icon = '', password = false, error, ...props }: _Object) => {
	const [eyeOn, setEyeOn] = useState(false)
	const [fieldType, setFieldType] = useState(type)
	const toggleEyeOn = () => {
		if (eyeOn === false) {
			setEyeOn(true)
			setFieldType('text')
		} else {
			setEyeOn(false)
			setFieldType('password')
		}
	}
	const customError: any = [{ required: 'Required', number: 'One number', uppercase: 'One uppercase', special: 'One special character', minLength: 'Min 8 characters' }]

	const getErrorClass = (key: any) => {
		if (error && error[key]) {
			return 'text-danger';
		} else if (error === 'Password is required') {
			return 'text-danger';
		} else {
			return 'text-success';
		}
	}

	return (
		<div className={`form-group mb-3 ${className}`}>
			{label && <label className="label-form mb-1">{label}{required && <span className="text-danger">*</span>}</label>}

			<input
				type={fieldType ? fieldType : 'text'}
				autoComplete="off"
				name={name}
				placeholder={placeholder}
				value={value ?? ''}
				onChange={onChange}
				className={`form-control ${error && 'invalid'} `}
				{...props}
			/>

			{icon && <img src={icon} alt="Icon" className="position-absolute translate-middle-y form-control-icon top-50 px-3" />}

			{type === 'password' &&
				<button
					type="button"
					className="btn show-password border-0"
					onClick={() => toggleEyeOn()}
				>
					{eyeOn ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
				</button>
			}

			{(error && typeof error === 'object' && password) || error === 'Password is required' ? (
				<ul className="d-flex gap-2 p-0">
					{Object.keys(customError[0]).map((key: any, index) => {
						return (
							<li key={index} className={`mt-2 invalid-feedback-${getErrorClass(key)} d-flex ${getErrorClass(key)}`}>
								{customError[0][key]}
							</li>
						)
					})}
				</ul>
			) : error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
		</div>
	)
}

export default InputField
