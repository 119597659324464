import React from 'react';

const Add = () => {
	return (
		<div>
			<h1>Add Edit</h1>
		</div>
	)
}

export default Add