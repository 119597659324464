import CommonService from './common.service';

class ChatService extends CommonService {
	async getChats() {
		return await this.get('get-messages')
	}

	async getChatDetail(id: string) {
		return await this.get(`chats/${id}/messages`)
	}

	async postChat(id: string, params: { [key: string]: boolean | string }) {
		return await this.post(`chats/${id}/messages`, params)
	}

	async deleteChat(id: string) {
		return await this.delete(`get-messages/${id}`)
	}
}

export const chatService = new ChatService();

