export const routes = {
	login: '/login',
	forgotPassword: '/forgot-password',
	resetPassword: '/reset-password',
	dashboard: '/app/dashboard',
	booking: '/app/bookings',
	customer: '/app/customers',
	gem: '/app/gems',
	qutoes: '/app/qutoes',
	inquiry: '/app/inquiries',
	changePassword: '/app/change-password',
	profile: '/app/profile',
	team: '/app/team',
	setting: '/app/settings',
	chat: '/app/chat'
}

