import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class CustomersService extends CommonService {
	async getCustomers(params?: { [key: string]: boolean | string }) {
		return await this.get(`users?${generateQueryParams(params)}`)
	}
	async getCustomerDetails(id: string | undefined) {
		return await this.get(`users/${id}`)
	}

	async createCustomers(params: { [key: string]: boolean | string }) {
		return await this.post('users', params)
	}

	async updateCustomers(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`users/${id}`, params)
	}

	async deleteCustomers(id: string) {
		return await this.delete(`users/${id}`)
	}
}

export const customersService = new CustomersService();

