import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { bookingsService } from 'services/booking.service';
import { _Object } from 'utils/interfaces';
import { Loading } from 'views/components';

const OpenBookingDetails = ({ selectedBooking }: _Object) => {
	const [details, setDetails] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (selectedBooking) {
			setLoading(true)
			bookingsService.getBookingDetails(selectedBooking).then((data) => {
				setDetails(data);
				setLoading(false)
			});
		}
	}, [selectedBooking]);

	return (
		<>
			<div className="gemstone-details-modal">
				<div
					className="modal fade"
					id="OpenBookingDetails"
					aria-labelledby="OpenBookingDetails"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="mb-0">Booking Details</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
								</button>
							</div>
							<div className="modal-body">
								{loading ? <div className="text-center"><Loading /></div> :
									<div className="row">
										<div className="d-flex gap-5">
											<div>
												{details?.email && <div className="name-details">
													<h6>Email </h6>
													<p>{details?.email}</p>
												</div>
												}
												{details?.phone_number && (
													<div className="details-description">
														<h6>Phone Number</h6>
														<p>{details?.phone_number}</p>
													</div>
												)}

												{details?.created_at && (
													<div className="create-date">
														<h6>Created date</h6>
														<p>{moment(details?.created_at).utc().format('DD MMMM, YYYY')}</p>
													</div>
												)}
											</div>
											<div>
												{details?.status && <div className="name-details">
													<h6>Status </h6>
													<p>{details?.status}</p>
												</div>
												}
												{details?.payment_status && <div className="name-details">
													<h6>Payment Status </h6>
													<p>{details?.payment_status}</p>
												</div>
												}
												{details?.order_note && <div className="name-details">
													<h6>Note </h6>
													<p>{details?.order_note}</p>
												</div>
												}
											</div>

											<div>
												{details?.total_price && (
													<div className="details-benefits">
														<h6>Total Price</h6>
														<p>{details?.total_price}</p>
													</div>
												)}
												{details?.order_number && (
													<div className="details-benefits">
														<h6>Order Number</h6>
														<p>{details?.order_number}</p>
													</div>
												)}
												{details?.belongs_to && (
													<div className="details-benefits">
														<h6>Belongs To</h6>
														<p>{details?.belongs_to}</p>
													</div>
												)}
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default OpenBookingDetails;

