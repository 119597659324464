import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from 'redux/store'
import { setLoggedInUser } from 'redux/slices/session.slice'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { authService } from 'services'
import './style.scss'
import { toast } from 'react-toastify'
import { _Object } from 'utils/interfaces'
import { Button, InputField } from 'views/components'
import { PhoneNumberField } from 'views/components/form-inputs'
import axios from 'axios'
import store from 'store';
import { DownloadIcon } from '../assets/icons'

const Profile = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { loggedInUser } = useSelector((state: _Object) => state.session);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [previewUrl, setPreviewUrl] = useState('');

	useEffect(() => {
		if (loggedInUser?.id) {
			setInitialData({
				name: loggedInUser?.name || '',
				phone_number: loggedInUser?.phone_number,
				dob: loggedInUser?.dob,
				tob: loggedInUser?.tob,
				birth_place: loggedInUser?.birth_place,
				email: loggedInUser?.email
			});
		} else {
			dispatch(setLoggedInUser());
		}
	}, [loggedInUser?.id]);

	const [initialData, setInitialData] = useState<_Object>({
		name: loggedInUser?.name || '',
		phone_number: loggedInUser?.phone_number,
		dob: loggedInUser?.dob,
		tob: loggedInUser?.tob,
		birth_place: loggedInUser?.birth_place,
		email: loggedInUser?.email
	});

	const formik = useFormik({
		initialValues: initialData,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			name: Yup.string().required('Name is required')
		}),
		onSubmit: async (values: any) => {
			handleUpload()
			const formatedValue = {
				birth_place: values.birth_place,
				dob: values.dob,
				tob: values.tob,
				gender: values.gender,
				name: values.name,
				phone_number: values.phone_number
			}
			authService.editProfile(formatedValue).then((result: _Object) => {
				if (result.error) {
					toast.error(result.message, { style: { backgroundColor: '#b7e9bc' } })
				}
				if (!result.error) {
					toast.success(result.message, { style: { backgroundColor: '#b7e9bc' } })
					authService.getMe().then((result: _Object) => {
						setInitialData(result);
						dispatch(setLoggedInUser())
					});
				}
			});
		}
	});

	const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setSelectedFile(file);
			setPreviewUrl(URL.createObjectURL(file));
		}
	};

	const handleUpload = async () => {
		if (selectedFile) {
			const data = new FormData();
			data.append('key', 'profile_image')
			data.append('entity_id', loggedInUser?.id);
			data.append('files', selectedFile);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				console.log('response', response);
			} catch (error) {
				console.error('Error uploading image:', error);
				alert(error);
			}
		}
	}

	return (
		<>
			<section className="change-passwrod-section">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h5>Edit Profile</h5>
								</div>
								<form onSubmit={formik.handleSubmit}>
									<div className="profile-form card-body pb-0">
										<div className="row">
											<div className="col-lg-3 col-md-12 col-sm-12 col-12">
												<div className="avatar-upload">
													<input
														type="file"
														id="imageUpload"
														accept=".png, .jpg, .jpeg"
														onChange={handleImageChange}
													/>
													<label htmlFor="imageUpload"></label>
													<div className="avatar-preview">
														<div className="image-preview">
															<a href="#">
																{previewUrl ? (
																	<img
																		src={previewUrl}
																		alt=""
																		width={240}
																		height={220}
																	/>
																)
																	:
																	<img
																		src={loggedInUser?.images?.path}
																		alt=""
																		width={240}
																		height={220}
																	/>
																}
															</a>
															<p>
																<img
																	src={DownloadIcon}
																	alt="Avatar Preview"
																	width={20}
																	height={20}

																/> &nbsp;&nbsp;Upload Image Here ...</p>
														</div>
														<div className="edit-images">
														</div>
													</div>
												</div>
											</div>

											<div className="col-lg-9 col-md-12 col-sm-12 col-12">
												<InputField
													label="Full Name"
													name="name"
													required={true}
													placeholder="Enter here"
													value={formik.values.name}
													onChange={formik.handleChange}
												/>
												<div className="col-12">
													<InputField
														type="email"
														name="email"
														label="Email"
														autoFocus
														required={true}
														value={formik?.values?.email}
														disabled={true}
													/>
												</div>
												<div className="col-12">
													<PhoneNumberField
														className="custom-phone-number-field"
														label="Phone Number"
														country="in"
														value={formik.values.phone_number}
														onChange={(phone_number) => {
															formik.setFieldValue('phone_number', phone_number);
														}}
														disabled={false}
														placeholder="Enter phone number..."
													/>
												</div>
											</div>
										</div>

										<div className="add-new-gemstone mt-2">
											<div className="row">

												<div className="col-lg-4 col-md-4 col-sm-12 col-12">
													<InputField
														label="Date of Birth"
														name="dob"
														type="date"
														placeholder="Enter here"
														value={(formik.values.dob)}
														onChange={formik.handleChange}
													/>
												</div>

												<div className="col-lg-4 col-md-4 col-sm-12 col-12">
													<InputField
														label="Birth Time"
														name="tob"
														type="time"
														placeholder="Enter here"
														value={formik.values.tob}
														onChange={formik.handleChange}
													/>

												</div>

												<div className="col-lg-4 col-md-4 col-sm-12 col-12">
													<InputField
														label="Birth Place"
														name="birth_place"
														placeholder="Enter here"
														value={formik.values.birth_place}
														onChange={formik.handleChange}
													/>
												</div>
											</div>
										</div>

									</div>
									<div className="card-footer border-top text-end">
										<Button
											type="submit"
											className="btn btn-primary"
											label="Update profile"
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Profile;
