import store from 'store';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { authService } from 'services';

const initialState = {
	isUserLoggedIn: store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') ? true : false,
	loggedInUser: {},
	globalSettings: {},
	printerDevice: ''
}

export const setLoggedInUser = createAsyncThunk('setLoggedInUser', async () => {
	return await authService.getMe()
})

const sessionSlice = createSlice({
	name: 'session',
	initialState: initialState,
	reducers: {
		setTokenSession: (state, action) => {
			state.isUserLoggedIn = true
			store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9', action.payload)
		},
		setDestroyAuth: (state) => {
			store.clearAll()
			state.isUserLoggedIn = false
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(setLoggedInUser.fulfilled, (state, action) => {
				state.loggedInUser = action.payload
			})
	}
});

export const { setTokenSession, setDestroyAuth } = sessionSlice.actions;

export default sessionSlice.reducer;

