import React, { useEffect, useState, useRef } from 'react';
import { _Object } from 'utils/interfaces';

const OTPField = ({ args }: _Object) => {
	const [otp, setOTP] = useState<{ [key: string]: string }>({});
	const inputRefs = useRef<HTMLInputElement[]>([]); // Array of input refs

	// Set focus on the first input on component mount
	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0].focus();
		}
	}, []);

	// Trigger onChange when the OTP is fully entered
	useEffect(() => {
		const otpValue = Object.values(otp).join(''); // Get full OTP as a string
		if (otpValue.length === args.length) {
			args.onChange(otpValue); // Call onChange prop only when OTP is fully entered
		}
		// Ensure the effect runs only when otp changes or args changes
	}, [otp]);

	// Clear OTP fields on resend
	useEffect(() => {
		if (args.resend) {
			setOTP({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' });
			if (inputRefs.current[0]) {
				inputRefs.current[0].focus(); // Focus back on the first input after resend
			}
		}
	}, []);

	const handleChange = (event: _Object) => {
		const { name, value } = event.target;
		if (/^\d$/.test(value) || value === '') {
			setOTP((prev) => ({ ...prev, [name]: value }));

			const index = parseInt(name, 10);
			if (value && inputRefs.current[index + 1]) {
				inputRefs.current[index + 1].focus();
			}
		}
	};

	const inputFocus = (event: _Object) => {
		if (event.key === 'Backspace' || event.key === 'Delete') {
			const index = parseInt(event.target.name, 10);
			if (index > 0 && !otp[index]) {
				inputRefs.current[index - 1].focus();
			}
		}
	};

	const handlePaste = (event: any) => {
		const value = event.clipboardData.getData('text');
		const otpArray = value.slice(0, args.length).split('');

		otpArray.forEach((item: any, i: number) => {
			setOTP((prev) => ({
				...prev,
				[i]: item
			}));

			if (inputRefs.current[i]) {
				inputRefs.current[i].focus();
			}
		});
	};

	return (
		<div className="security-code d-flex">
			{Array.from({ length: args.length }).map((_, i: number) => (
				<input
					key={i}
					ref={(el: HTMLInputElement | null) => {
						if (el) {
							inputRefs.current[i] = el;
						}
					}}
					type={args.secure ? 'password' : 'text'}
					name={`${i}`}
					tabIndex={i + 1}
					maxLength={1}
					value={otp[i] || ''}
					className="form-control mx-1 text-center"
					style={{ width: '2.75rem', height: '2.5rem' }}
					onChange={handleChange}
					onKeyDown={inputFocus}
					onPaste={handlePaste}
				/>
			))}
		</div>
	);
};

export default OTPField;
