import React from 'react'

import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Logo } from 'assets/images'
import { setDestroyAuth } from 'redux/slices/session.slice'
import { Booking, Customers, CustomersService, Dashboard, EditProfile, Gem, Inquiry, Logout, Setting } from './assets/icons'
import { routes } from 'utils/routes'
import './style.scss'
import { closeModal } from 'utils'
import { SessionState } from 'utils/interfaces'

const Sidebar = () => {
	const dispatch = useDispatch()
	const { loggedInUser } = useSelector((state: { [key: string]: SessionState }) => state.session);
	return (
		<>
			<div id="sidebar" className="sidebar-colums">

				<div className="main-logo">
					<NavLink to="/app/dashboard" className="d-inline-block">
						<img src={Logo} alt="Logo" className="img-fluid" width={180} height={41} />
					</NavLink>
				</div>

				<div className="sidebar-inner">
					<ul className="list-unstyled">
						<h6>Main menu</h6>
						{(loggedInUser?.role === 'admin' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'receptionist') &&
							<li>
								<NavLink className="nav-link" to={routes.dashboard}>
									<span>
										<img src={Dashboard} alt="Dashboard" width={15} height={15} />
									</span>
									Dashboard
								</NavLink>
							</li>
						}
						{(loggedInUser?.role === 'admin' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'gems_manager') &&
							< li >
								<NavLink className="nav-link" to={routes.gem}>
									<span>
										<img src={Gem} alt="Gems" width={15} height={15} />
									</span>
									Gemstones
								</NavLink>
							</li>
						}
						{(loggedInUser?.role === 'admin' || loggedInUser?.role === 'gems_manager' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'receptionist') &&
							<li>
								<NavLink className="nav-link" to={routes.inquiry}>
									<span>
										<img src={Inquiry} alt="Inquiry" width={15} height={15} />
									</span>
									Enquiries
								</NavLink>
							</li>
						}
						{(loggedInUser?.role === 'admin' || loggedInUser?.role === 'astrologer' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'receptionist') &&
							<li>
								<NavLink className="nav-link" to={routes.booking}>
									<span>
										<img src={Booking} alt="Booking" width={15} height={15} />
									</span>
									Bookings
								</NavLink>
							</li>
						}
						{(loggedInUser?.role === 'admin' || loggedInUser?.role === 'customer' || loggedInUser?.role === 'staff' || loggedInUser?.role === 'manager' || loggedInUser?.role === 'receptionist') &&
							<>
								<li>
									<NavLink className="nav-link" to={routes.customer}>
										<span>
											<img src={CustomersService} alt="Customers Service" width={15} height={15} />
										</span>
										Customers
									</NavLink>
								</li>

								<li>
									<NavLink className="nav-link" to={routes.team}>
										<span>
											<img src={Customers} alt="Customers" width={15} height={15} />
										</span>
										Staff
									</NavLink>
								</li>
							</>
						}
					</ul>

					<ul className="list-unstyled">
						<h6>Account</h6>

						<li>
							<NavLink className="nav-link" to={routes.profile}>
								<span>
									<img src={EditProfile} alt="Edit Profile" width={15} height={15} />
								</span>
								Edit Profile
							</NavLink>
						</li>
						{(loggedInUser?.role === 'admin') &&
							<li>
								<NavLink className="nav-link" to={routes.setting}>
									<span>
										<img src={Setting} alt="Setting" width={15} height={15} />
									</span> Settings
								</NavLink>
							</li>
						}
					</ul>
				</div >

				<div className="logout-col">
					<button
						className="btn"
						data-bs-toggle="modal" data-bs-target="#LogOut"
					>
						<span>
							<img src={Logout} alt="Logout" width={15} height={15} />
						</span>
						Logout
					</button>
				</div>
			</div >

			<div className="modal fade" id="LogOut" aria-labelledby="LogOutLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content">
						<div className="modal-header bg-white">
							<h5 className="mb-0 mx-auto">Confirm Logout</h5>
							<button type="button" className="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body text-center">
							<p>Are you sure you want to log out?<br /> Any unsaved changes will be lost.</p>
						</div>
						<div className="modal-footer justify-center border-0 pb-5 bg-white">
							<button type="button" className="btn btn-secondary mx-3" data-bs-dismiss="modal" aria-label="Close">No</button>
							<button type="submit" className={'btn btn-danger'} onClick={() => { closeModal('LogOut'), dispatch(setDestroyAuth()) }}>Yes</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Sidebar