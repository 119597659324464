import React, { useEffect, useState } from 'react';

import './style.scss'
import axios from 'axios';
import store from 'store';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import { closeModal } from 'utils';
import { _Object } from 'utils/interfaces';
import AddEditQuotes from '../qutoe/add-edit';
import { quotesService } from 'services/qutoes.services';

import { Button, DeleteItem, InputField, TextArea } from 'views/components';
import { Close, DeleteButton, DownloadIcon, EditButton, Plus, PlusArrow } from '../assets/icons';
import { meadiaService } from 'services';

const Setting = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [feeLoading, setFeeLoading] = useState<boolean>(false);
	const [mediaLoading, setMediaLoading] = useState<boolean>(false);
	const [banner, setBanner] = useState<any>();

	const [quotes, setQuotes] = useState<_Object>({});
	const [consultant, setConsultant] = useState<any>([]);

	const [bio, setBio] = useState<_Object>({});
	const [selectedQuotes, setSelectedQuotes] = useState<any>('');
	const [selectedConsultant, setSelectedConsultant] = useState<any>('');

	const [previewUrl, setPreviewUrl] = useState('');

	const getQutoes = () => {
		setLoading(true)
		quotesService.getQutoes({}).then((data) => {
			setQuotes(data)
			setLoading(false)
		})
		bioDetail()
	}

	const getConsultantFee = () => {
		setFeeLoading(true)
		quotesService.getConsultantFee().then((data) => {
			setConsultant(data)
			setFeeLoading(false)
		})
	}

	const bioDetail = () => {
		quotesService.getUserDetails().then((data) => {
			data.id && setBio(data)
			formik.setFieldValue('title', data.title);
			formik.setFieldValue('description', data.description);
		})
	}

	useEffect(() => {
		getQutoes()
		getConsultantFee()
		formik1.setFieldValue('consultant', '0')
	}, [])

	const handleImageChange = async (e: any) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setPreviewUrl(URL.createObjectURL(file));
		}
		if (file) {
			const data = new FormData();
			data.append('key', 'images');
			data.append('entity_id', bio.id);
			data.append('files', file);
			try {
				const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				setPreviewUrl('')
				bioDetail()
				console.log(response);
			} catch (error) {
				console.error('Error uploading image:', error);
				alert(error);
			}
		}
	};

	const deleteItem = () => {
		setLoading(true)
		quotesService.deleteQutoe(selectedQuotes).then((data: _Object) => {
			!data.error && getQutoes()
			data.error && toast.error(data?.message)
			setLoading(false)
			closeModal('DeleteQuotes')
		})
	}

	const formik: any = useFormik({
		initialValues: { title: '', description: '' },
		enableReinitialize: true,
		validationSchema: Yup.object({
			description: Yup.string().label('Description').required()
		}),
		onSubmit: (values) => {
			setLoading(true)
			quotesService.updateBio({ title: values.title, description: values.description }).then((item: any) => {
				if (!item.error) {
					bioDetail()
					// setFile(null)
					setLoading(false)
					closeModal('EditBio')
				}
			})
		}
	});

	const formik1: any = useFormik({
		initialValues: { consultant: '', fee: '' },
		enableReinitialize: true,
		validationSchema: Yup.object({
			consultant: Yup.string().label('Consultant').required(),
			fee: Yup.string().label('Fee').required()
		}),
		onSubmit: (values) => {
			if (selectedConsultant) {
				quotesService.updateConsultantFee(selectedConsultant, { type: values.consultant, fees: values.fee }).then((data: any) => {
					if (!data?.error) {
						closeModal('NewConsultant')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						getConsultantFee()
					}
				})
			} else {
				quotesService.createNewConsultantFee({ type: values.consultant, fees: values.fee }).then((data: any) => {
					if (!data?.error) {
						closeModal('NewConsultant')
						toast.success(data.message, { style: { backgroundColor: '#b7e9bc' } })
						getConsultantFee()
					}
				})
			}
		}
	});

	const removeMediaImage = (e: any) => {
		meadiaService.deleteMedia(e).then((data: any) => {
			!data.error && bioDetail()
		})
	}

	const editConsultantFee = (id: any) => {
		const data = consultant?.items?.find((item: any) => item.id === id)
		setSelectedConsultant(data?.id)
		formik1.setFieldValue('consultant', data?.type)
		formik1.setFieldValue('fee', data?.fees)
	}

	const isEmptyConsultant = consultant?.items?.length === 0 || consultant?.items?.length === undefined

	const isEmptyImage = bio?.images === null

	const handleBannerImageChange = async (e: any) => {
		const file = e.target.files ? e.target.files[0] : null;
		if (file) {
			setPreviewUrl(URL.createObjectURL(file));
			setBanner(file)
		}
	};

	const deleteBannerImg = (id: any) => {
		setMediaLoading(true)
		meadiaService.deleteMedia(id).then((data) => {
			if (!data?.error) {
				setMediaLoading(false)
				toast.success(data.message)
				bioDetail()
				closeModal('deleteBannerImg')
			} else {
				toast.error(data.message)
			}
		})
	}

	const submitBannerImage = async () => {
		setMediaLoading(true)
		if (banner) {
			const data = new FormData();
			data.append('entity_id', bio.id);
			data.append('key', 'banner');
			data.append('files', banner);

			try {
				const response: any = await axios.post(`${process.env.REACT_APP_BASE_URL}/v1/admin/media`, data, {
					headers: { 'Content-Type': 'multipart/form-data', 'Authorization': store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcg42Wb9') }
				});
				setMediaLoading(false)
				bioDetail()
				setPreviewUrl('')
				closeModal('NewConsultant1')
				toast.success(response?.data.message)
			} catch (error) {
				console.error('Error uploading image:', error);
				alert(error);
			}
		}
	}

	if (!bio.id) return
	return (
		<>
			<section className="setting-section">
				<div className="edit-images"
					data-bs-toggle="modal"
					data-bs-target="#EditBio">
				</div>
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-3 col-md-12 col-sm-12 col-12 my-auto">
							{isEmptyImage &&
								<div className="col-12">
									<div className="avatar-upload">
										<input
											type="file"
											id="imageUpload"
											accept=".png, .jpg, .jpeg"
											onChange={(e) => {
												handleImageChange(e)
											}}
										/>
										<label htmlFor="imageUpload"></label>
										<div className="avatar-preview">
											<div className="image-preview">
												<a href="#">
													{previewUrl && (
														<img
															src={previewUrl}
															alt=""
															width={515}
															height={220}
														/>
													)
													}
												</a>

												<p>
													<img
														src={DownloadIcon}
														alt=""
														width={20}
														height={20}

													/> &nbsp;&nbsp;Upload Image Here ...</p>
											</div>
										</div>

									</div>
								</div>
							}

							<div className="setting-images-group">
								<div className="row">
									{bio?.images?.length === 0 &&
										<div className="col">
											<div className="avatar-upload">
												<input
													type="file"
													id="imageUpload"
													accept=".png, .jpg, .jpeg"
													onChange={(e) => {
														handleImageChange(e)
													}}
												/>
												<label htmlFor="imageUpload"></label>
												<div className="avatar-preview">
													<div className="image-preview">
														<a href="#">
															{previewUrl ? (
																<img
																	src={previewUrl}
																	alt="Avatar Preview"
																	width={515}
																	height={220}
																/>
															)
																: (
																	<img
																		src={bio?.images?.path}
																		alt="Avatar Preview"
																		width={515}
																		height={220}
																	/>
																)
															}
														</a>

														<p>
															<img
																src={DownloadIcon}
																alt="Avatar Preview"
																width={20}
																height={20}

															/> &nbsp;&nbsp;Upload Image Here ...</p>
													</div>
												</div>

											</div>
										</div>
									}

									{bio?.images?.length > 0 && bio?.images?.map((img: any) => (
										<div className="col" key={img?.id}>
											<a href="#" className="close-images" onClick={(e) => { e.preventDefault(); removeMediaImage(img?.id); }}>
												<img src={img?.path} alt="User Images" width={100} height={100} className="user-upload-images" />
												<span className="close-icon-top">
													<img src={Close} alt="Close" width={9} height={9} />
												</span>
											</a>
										</div>
									))}

									{bio?.images?.length < 4 && !isEmptyImage &&
										< div className="col-12">
											<div className="avatar-upload">
												<input
													type="file"
													id="imageUpload"
													accept=".png, .jpg, .jpeg"
													onChange={(e) => {
														handleImageChange(e)
													}}
												/>
												<label htmlFor="imageUpload"></label>
												<div className="avatar-preview add-more-images">
													<div className="image-preview">
														<a href="#">
															{previewUrl && (
																<img
																	src={previewUrl}
																	alt=""
																	width={515}
																	height={220}
																/>
															)
															}
														</a>

														<p>
															<img
																src={PlusArrow}
																alt=""
																width={20}
																height={20}

															/> </p>
													</div>
												</div>

											</div>
										</div>
									}
								</div>
							</div>
						</div>

						<div className="col-lg-9 col-md-12 col-sm-12 col-12 my-auto">
							<h6>{bio?.title}</h6>
							<p className="mb-0">{bio?.description}</p>
						</div>

					</div>
				</div>
			</section >
			<section className="daily-section">
				<div className="container-fluid">
					<div className="card">
						<div className="card-header space-between align-middles">
							<h6 className="mb-0">Daily Quotes</h6>
							<button
								type="button"
								className="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#NewAppontment"
								onClick={() => setSelectedQuotes('')}
							>
								<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
								Add New
							</button>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th style={{ width: '50px' }}>#</th>
											<th>Name</th>
											<th>Description</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>

									<tbody>
										{loading ?
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<div className="spinner-border text-secondary" role="status">
														</div>
													</div>
												</td>
											</tr> :

											quotes?.items?.map((item: { id: string, name: string, description: string, benefit: string, how_to_use: string, created_at: string, updated_at: string, }, i: number) => {
												return (
													<>
														<tr>
															<td>{i + 1}</td>
															<td>
																<p className="mb-1">{item?.name}</p>
															</td>
															<td>
																<p className="mb-1">{`${item?.description?.slice(0, 30)}`}</p>
															</td>
															<td>
																<ul className="list-inline">
																	<li className="list-inline-item">
																		<button type="button" onClick={() => setSelectedQuotes(item?.id)} className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#DeleteQuotes">
																			<img src={DeleteButton} alt="Delete" width={16} height={16} />
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													</>
												)
											})}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="card mt-5">
						<div className="card-header space-between align-middles">
							<h6 className="mb-0">Consultant And Fees</h6>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th style={{ width: '50px' }}>#</th>
											<th>Name</th>
											<th>Fees</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>

									<tbody>
										{isEmptyConsultant &&
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<p>No record found!</p>
													</div>
												</td>
											</tr>
										}
										{feeLoading ?
											<tr>
												<td colSpan={12} className="text-center">
													<div className="d-flex justify-content-center">
														<div className="spinner-border text-secondary" role="status">
														</div>
													</div>
												</td>
											</tr> :

											consultant && consultant?.items?.map((item: any, i: number) => {
												return (
													<>
														<tr>
															<td>{i + 1}</td>
															<td>
																<p className="mb-1">{item?.type === '1' ? 'Guruma' : 'Other'}</p>
															</td>
															<td>
																<p className="mb-1">{`${item?.fees}`}</p>
															</td>
															<td>
																<ul className="list-inline">
																	<li className="list-inline-item">
																		<a href={''} className="btn btn-success eye-edit p-0" type="button"
																			data-bs-toggle="modal" data-bs-target="#NewConsultant"
																			onClick={() => editConsultantFee(item?.id)
																			}>
																			<img src={EditButton} alt="Edit" width={22} height={20} />
																		</a>
																	</li>
																</ul>
															</td>
														</tr>
													</>
												)
											})}
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div className="card mt-5">
						<div className="card-header space-between align-middles">
							<h6 className="mb-0">Banner images</h6>
							<button
								type="button"
								className="btn btn-primary"
								data-bs-toggle="modal"
								data-bs-target="#NewConsultant1"
							>
								<img src={Plus} alt="Add" width={15} height={15} />&nbsp;
								Add New
							</button>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th style={{ width: '50px' }}>#</th>
											<th>Avtar</th>
											<th className="action-button">Action</th>
										</tr>
									</thead>

									<tbody>
										{bio?.banner_image?.length === 0 ?
											<tr className="text-center">
												<td className="text-center" colSpan={12}>No Record found!</td>
											</tr> :
											bio?.banner_image?.map((data: any, i: number) => {
												return (
													<>
														<tr key={i}>
															<td>{i + 1}</td>
															<td><img src={`${data?.path}`} className="gems-avtar object-fit-cover" style={{ borderRadius: '30px' }} height={50} width={50} /></td>
															<td>
																<ul className="list-inline">
																	<li className="list-inline-item">
																		<button type="button" className="btn btn-primary p-0 eye-delete" data-bs-toggle="modal" data-bs-target="#deleteBannerImg" onClick={() => deleteBannerImg(data?.id)}>
																			<img src={DeleteButton} alt="Delete" width={16} height={16} />
																		</button>
																	</li>
																</ul>
															</td>
														</tr>
													</>
												)
											})
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</section >
			<AddEditQuotes getQutoes={getQutoes} selectedQuotes={selectedQuotes} />
			<DeleteItem title={'Quotes'} modalId={'DeleteQuotes'} deleteItem={deleteItem} />

			<div className="modal fade" id="NewConsultant" aria-labelledby="NewConsultant" aria-hidden="true" data-bs-backdrop="static">
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik1.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0">{selectedConsultant ? 'Edit Consultant' : 'New Consultant'}</h5>
								<button type="button" className="btn-close"
									data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">

								<div className="row">
									<InputField
										type="text"
										name="fee"
										label="Fee"
										required={true}
										placeholder="Enter here"
										value={formik1.values.fee}
										onChange={formik1.handleChange}
										onBlur={formik1.handleBlur}
										error={formik1?.touched.fee && formik1.errors.fee || ''}
									/>
								</div>

							</div>
							<div className="modal-footer">
								<Button type="submit" label="Submit" className={`btn btn-primary ${feeLoading ? 'btn-loading' : ''} `}
									disabled={feeLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="modal fade" id="NewConsultant1" aria-labelledby="NewConsultant1" aria-hidden="true" data-bs-backdrop="static">
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="mb-0">Add new banner image</h5>
							<button type="button" className="btn-close"
								data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="avatar-upload">
									<input
										type="file"
										id="imageUpload1"
										accept=".png, .jpg, .jpeg"
										onChange={(e) => {
											handleBannerImageChange(e)
										}}
									/>
									<label htmlFor="imageUpload1"></label>
									<div className="avatar-preview m-auto">
										<div className="image-preview">
											<a href="#">
												{previewUrl && (
													<img
														src={previewUrl}
														alt=""
														width={515}
														height={220}
													/>
												)
												}
											</a>

											<p>
												<img
													src={DownloadIcon}
													alt=""
													width={20}
													height={20}

												/> &nbsp;&nbsp;Upload Image Here ...</p>
										</div>
									</div>

								</div>
							</div>
						</div>
						<div className="modal-footer">
							<Button type="submit" onClick={submitBannerImage} label="Submit" className={`btn btn-primary ${mediaLoading ? 'btn-loading' : ''} `}
								disabled={mediaLoading} />
						</div>
					</div>
				</div>
			</div>

			<div
				className="modal fade"
				id="EditBio"
				aria-labelledby="EditBio"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-dialog-centered modal-lg">
					<div className="modal-content">
						<form onSubmit={formik.handleSubmit}>
							<div className="modal-header">
								<h5 className="mb-0">Update Bio</h5>
								<button
									type="button"
									className="btn-close"
									onClick={() => {
										formik.resetForm()
									}}
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div className="modal-body">
								<div className="row">
									<div className="col-12">
										<InputField
											name="title"
											label="Title"
											required={true}
											placeholder="Enter here"
											value={formik.values.title}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={
												(formik.touched.title && formik.errors.title) || ''
											}
										/>
										<TextArea
											name="description"
											label="Description"
											rows={4}
											required={true}
											placeholder="Enter here"
											value={formik.values.description}
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={
												(formik.touched.description && formik.errors.description) || ''
											}
										/>
									</div>

								</div>
							</div>
							<div className="modal-footer">
								<Button type="submit" label="Update" className={`btn btn-primary ${loading ? 'btn-loading' : ''} `} disabled={loading} />
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="modal fade" id={'deleteBannerImg'} aria-labelledby="deleteBannerImg" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
				<div className="modal-dialog modal-dialog-centered ">
					<div className="modal-content">
						<div className="modal-header bg-white">
							<h5 className="mb-0 mx-auto">Confirm Delete Banner Image</h5>
							<button type="button" className="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body text-center">
							<p>Do you really want to delete this Banner Image?</p>
						</div>
						<div className="modal-footer justify-center border-0 pb-5 bg-white">
							<button type="button" className="btn btn-secondary mx-3" data-bs-dismiss="modal" aria-label="Close">No</button>
							<button type="submit" className={'btn btn-danger'} onClick={deleteBannerImg}>Yes</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Setting