import React from 'react'
import ReactPaginate from 'react-paginate'
import { _Object } from 'utils/interfaces'
import './style.scss'
const Pagination = ({ total_pages, onClick }: _Object) => {
	// const handlePageClick = (data: { selected: number }) => {
	// 	onClick(data.selected + 1);
	// };
	return (
		<>
			<ReactPaginate
				breakLabel=""
				nextLabel=""
				previousLabel=""
				onPageChange={onClick}
				pageCount={total_pages}
				pageClassName="page-item"
				pageLinkClassName="page-link"
				previousClassName="page-item"
				previousLinkClassName="page-link previous-arrow"
				nextClassName="page-item"
				nextLinkClassName="page-link next-arrow"
				breakClassName="page-item"
				breakLinkClassName="page-link"
				containerClassName="pagination"
				activeClassName="active"
			/>
		</>
	)
}
export default Pagination
