import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class GemsService extends CommonService {
	async getGems(params?: { [key: string]: boolean | string }) {
		return await this.get(`gems?${generateQueryParams(params)}`)
	}
	async getGemsDetails(id: string | undefined) {
		return await this.get(`gems/${id}`)
	}

	async createGems(params: { [key: string]: boolean | string }) {
		return await this.post('gems', params)
	}

	async updateGems(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`gems/${id}`, params)
	}

	async deleteGems(id: string) {
		return await this.delete(`gems/${id}`)
	}
}

export const gemsService = new GemsService();

