import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class QuotesService extends CommonService {
	async getQutoes(params?: { [key: string]: boolean | string }) {
		return await this.get(`quotes?${generateQueryParams(params)}`)
	}
	async getQutoeDetails(id: string | undefined) {
		return await this.get(`quotes/${id}`)
	}
	async getUserDetails() {
		return await this.get('bio')
	}

	async updateBio(params: { [key: string]: boolean | string }) {
		return await this.put('bio', params)
	}

	async createQutoes(params: { [key: string]: boolean | string }) {
		return await this.post('quotes', params)
	}

	async createNewConsultantFee(params: { [key: string]: boolean | string }) {
		return await this.post('consultation-fees', params)
	}

	async getConsultantFee() {
		return await this.get('consultation-fees')
	}

	async updateConsultantFee(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`consultation-fees/${id}`, params)
	}

	async updateQutoes(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`quotes/${id}`, params)
	}

	async deleteQutoe(id: string) {
		return await this.delete(`quotes/${id}`)
	}
}

export const quotesService = new QuotesService();

