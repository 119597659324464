import React from 'react';
import {
	BtnBold,
	BtnBulletList,
	BtnClearFormatting,
	BtnItalic,
	BtnLink,
	BtnNumberedList,
	BtnRedo,
	BtnStrikeThrough,
	BtnStyles,
	BtnUnderline,
	BtnUndo,
	Editor,
	EditorProvider,
	HtmlButton,
	Separator,
	Toolbar
} from 'react-simple-wysiwyg';
import { _Object } from 'utils/interfaces';

const WysiwygEditor = ({ value, onChange, label, required, error }: _Object) => {
	const handleEditorChange = (event: _Object) => {
		onChange(event.target.value);
	};

	return (
		<>
			{label && <label className="label-form mb-1">{label} {required && <span className="text-danger">*</span>}</label>}
			<EditorProvider>
				<Toolbar>
					<BtnUndo />
					<BtnRedo />
					<Separator />
					<BtnBold />
					<BtnItalic />
					<BtnUnderline />
					<BtnStrikeThrough />
					<Separator />
					<BtnNumberedList />
					<BtnBulletList />
					<Separator />
					<BtnLink />
					<BtnClearFormatting />
					<HtmlButton />
					<Separator />
					<BtnStyles />
				</Toolbar>
				<Editor
					value={value}
					onChange={handleEditorChange}
				/>
			</EditorProvider>
			{error && <span className="invalid-feedback text-danger d-block mt-1">{error}</span>}
		</>
	);
};

export default WysiwygEditor;
