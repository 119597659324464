import React, { useState } from 'react';

import * as Yup from 'yup'
import { useFormik } from 'formik';

import { _Object } from 'utils/interfaces';
import { authService } from 'services';
import { Button, InputField, LinkButton } from 'views/components';
import { Logo } from 'assets/images';
import { ArrowLeft, HandMobile } from './images';
import { useNavigate } from 'react-router-dom';
import { routes } from 'utils';

const Forgot = () => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<{ message?: string, error?: boolean }>({})

	const formik = useFormik({
		initialValues: { email: '' },
		enableReinitialize: true,

		validationSchema: Yup.object().shape({
			email: Yup.string().email('Invalid email address').required('Email is required')
		}),

		onSubmit: async (values: any) => {
			setLoading(true)
			authService.forgotPassword(values).then((data: _Object) => {
				setError(data)
				if (data.error === false) {
					setTimeout(() => {
						navigate(`${routes.resetPassword}`)
						formik.resetForm();
					}, 2000);
				}
				setLoading(false)
			})
		}
	})

	return (
		<>
			<div className="auth-form align-middles">
				<div className="col-lg-8 col-md-12">
					<div className="card">
						<div className="row">
							<div className="col-lg-6 col-md-12 my-auto">
								<div className="login-form-col">
									<div className="brand-logo">
										<img src={Logo} alt="Logo" width={15} height={15} className="brand-logo-images" />
										<h4>Forgot Your Password?</h4>
										<p>Enter your email to receive instructions to reset password.
										</p>
									</div>

									<form className="row align-items-center "
										onSubmit={formik.handleSubmit}>
										<InputField
											className=""
											type="email"
											name="email"
											label="Email"
											required={true}
											value={formik.values.email}
											onChange={(e: any) => {
												formik.handleChange(e)
												setError({})
											}}
											onBlur={formik.handleBlur}
											error={formik?.touched.email && formik.errors.email}
										/>

										<div className="col-12">
											<Button
												type="submit"
												name="Reset password"
												className="primary mt-2"
												loading={loading}
											/>
										</div>
									</form>

									<div className="login-link-button text-start">
										<a className="btn btn-link" href="/login">
											<img src={ArrowLeft} alt="Arrow Left" height="13" width="13" className="brand-logo-images" />&nbsp;
											Back to login</a>
									</div>
									{error?.message && <p className="text-center text-danger">{error?.message}</p>}
								</div>
							</div>
							<div className="col-lg-6 col-md-12">
								<div className="hand-moblie-col">
									<img src={HandMobile} alt="Hand Mobile" />
								</div>
							</div>

						</div>
					</div>

					<div className="already-account">
						<p>
							Already have an account? <LinkButton label="Sign In" path="/login" className="link" />
						</p>
					</div>
				</div>
			</div>
		</>
	)
}
export default Forgot