import React from 'react'

const DeleteItem = ({ title, modalId, deleteItem }: any) => {
	return (
		<div className="modal fade" id={`${modalId}`} aria-labelledby="DeleteGemsLabel" aria-hidden="true" data-bs-backdrop="static" tabIndex={-1}>
			<div className="modal-dialog modal-dialog-centered ">
				<div className="modal-content">
					<div className="modal-header bg-white">
						<h5 className="mb-0 mx-auto">Confirm Delete {title}</h5>
						<button type="button" className="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body text-center">
						<p>Do you really want to delete this {title}?</p>
					</div>
					<div className="modal-footer justify-center border-0 pb-5 bg-white">
						<button type="button" className="btn btn-secondary mx-3" data-bs-dismiss="modal" aria-label="Close">No</button>
						<button type="submit" className={'btn btn-danger'} onClick={deleteItem}>Yes</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default DeleteItem