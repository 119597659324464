import React, { useEffect, useState } from 'react';
import './style.scss'

import { EyeButton } from '../assets/icons';
import { _Object } from 'utils/interfaces';
import { enquirieService } from 'services';
import { capitalizeFirstLetter, dateFormat, getSortIcon, handleFilters, routes } from 'utils';
import Pagination from 'views/components/form-inputs/pagination';
import { useFormik } from 'formik';
import refreshIcon from '../../../../assets/images/refresh.svg'
import DropdownFilter from 'views/components/form-inputs/dropdown-filter';

const Inquiries = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [enquires, setEnquires] = useState<_Object>({});
	const [filterData, setFilterData] = useState<_Object>({
		page: 1,
		sort: '-created_at'
	});
	const getEnquires = () => {
		setLoading(true)
		enquirieService.getEnquires(filterData).then((data) => {
			setEnquires(data)
			setLoading(false)
		})
	}

	useEffect(() => {
		getEnquires()
	}, [filterData])

	const formik = useFormik({
		initialValues: filterData,
		enableReinitialize: true,
		onSubmit: (values: _Object) => {
			if (values.q.trim() !== '') {
				setFilterData((prev) => ({
					...prev,
					q: values.q,
					page: 1
				}));
			}
		}
	});
	console.log(formik)

	return (
		<>
			<section className="inquiry-section">
				<div className="container-fluid">
					<div className="row">
						<div className="card">
							<div className="card-header space-between align-middles">
								<h6 className="mb-0">Enquiries</h6>
								<div>
									{filterData.filter && <button
										type="button"
										className="btn btn-primary me-3"
										onClick={() => {
											setFilterData({
												page: 1,
												sort: '-created_at'
											})
										}}
									>Clear All</button>}
									<button className="btn btn-primary" onClick={() => window.location.reload()} >
										<img src={refreshIcon} width={17} height={17} /></button>
								</div>
							</div>

							<div className="card-body">
								<div className="table-responsive">
									<table className="table">
										<thead>
											<tr>
												<th style={{ width: '40px' }}>#</th>
												<th style={{ width: '100px', whiteSpace: 'nowrap' }}>Enquiry Id</th>
												<th style={{ width: '130px', whiteSpace: 'nowrap' }}>
													<button
														className="btn p-0 border-0"
														onClick={() => { handleFilters('sort', filterData.sort === '-name' ? 'name' : '-name', setFilterData) }}>
														Name
														{getSortIcon(filterData.sort.includes('name') ? filterData.sort : '')}
													</button>
												</th>
												<th style={{ whiteSpace: 'nowrap', width: '300px' }}>Contact Info.</th>
												<th>Description</th>
												<th style={{ width: '130px', whiteSpace: 'nowrap' }}>Gems Name</th>
												<th style={{ width: '130px', whiteSpace: 'nowrap' }}>
													<button
														className="btn p-0 border-0"
														onClick={() => { handleFilters('sort', filterData.sort === '-created_at' ? 'created_at' : '-created_at', setFilterData) }}>
														Created date
														{getSortIcon(filterData.sort.includes('created_at') ? filterData.sort : '')}
													</button>
												</th>
												<DropdownFilter
													title="Status"
													options={[
														{ label: 'New', value: 'new' },
														{ label: 'Close', value: 'close' }
													]}
													filterKey="status"
													filterData={filterData}
													setFilterData={setFilterData}
												/>
												<th className="action-button" style={{ width: '90px' }}>Action</th>
											</tr>
										</thead>

										<tbody>
											{loading ?
												(
													Array.from({ length: 5 }).map((_, index) => (
														<tr key={index}>
															{Array.from({ length: 9 }).map((_, cellIndex) => (
																<td className="placeholder-glow" key={cellIndex}>
																	<span className="placeholder col-12"></span>
																</td>
															))}
														</tr>
													))
												) :
												enquires?.items?.length === 0 ?
													<tr>
														<td colSpan={12} className="text-center">
															No record found!
														</td>
													</tr>
													:
													enquires?.items?.map((item: { gem: any, enquiry_number: string, status: string, description: string, id: string, name: string, email: string, phone_number: number, created_at: string, updated_at: string }, i: number) => {
														return (
															<tr key={item.name + i}>
																<td>
																	{i + 1}
																</td>
																<td>
																	<p className="mb-1">{item.enquiry_number}</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">{item.name}</p>
																</td>
																<td>
																	<a href={`mailto:${item.email}`}>
																		<p className="mb-1">{item.email}</p>
																	</a>
																	<a href={`tel:${item.phone_number}`}>
																		<p className="mb-1">{item.phone_number}</p>
																	</a>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.description?.slice(0, 30) ? item?.description?.slice(0, 30) : '-'}
																	</p>
																</td>

																<td>
																	<p className="mb-1 text-capitalize">
																		{item?.gem?.gem_name ? item?.gem?.gem_name : '-'}
																	</p>
																</td>

																<td>
																	<p className="mb-1 text-capitalize">
																		{dateFormat(item.created_at)}
																	</p>
																</td>
																<td>
																	<p className="mb-1 text-capitalize">{capitalizeFirstLetter(item.status)}</p>
																</td>

																<td>
																	<ul className="list-inline">
																		<li className="list-inline-item">
																			<a href={`${routes.inquiry}/${item.id}`} className="btn btn-secondary eye-button p-0" type="button">
																				<img src={EyeButton} alt="Eye" width={22} height={20} />
																			</a>
																		</li>
																	</ul>
																</td>
															</tr>
														)
													})}
										</tbody>
									</table>
								</div>
								{enquires?.pagination?.total_pages > 1 &&
									<div className="card-footer">
										<div className="pagination-wrap">
											<Pagination
												total_pages={enquires?.pagination?.total_pages}
												current_page={enquires?.pagination?.current_page}
												onClick={(i: { [key: string]: number }) =>
													setFilterData((prev) => ({
														...prev,
														page: i.selected + 1
													}))
												}
											/>
										</div>
									</div>}
							</div>
						</div>

					</div>
				</div>
			</section >
		</>
	)
}

export default Inquiries