import CommonService from './common.service';

class MeadiaService extends CommonService {
	async getMedia(id: string | undefined) {
		return await this.get(`media/${id}`)
	}

	async deleteMedia(id: string) {
		return await this.delete(`media/${id}`)
	}
}

export const meadiaService = new MeadiaService();

