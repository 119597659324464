import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Header, Sidebar } from 'views/components'

const PrivateLayout = () => {
	return (
		<Suspense>
			<Sidebar />
			<div className="main-content">
				<Suspense>
					<Header />
					<main className="main-wrapper">
						<Outlet />
					</main>
				</Suspense>
			</div>
		</Suspense>
	)
}
export default PrivateLayout