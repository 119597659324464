import React from 'react';
import { Button, InputField } from 'views/components';
import './style.scss'

const ChangePassword = () => {
	return (
		<section className="change-passwrod-section align-middles">
			<div className="container">
				<div className="row">
					<div className="col-6">
						<div className="card">
							<div className="card-header">
								<h5>Change Password</h5>
							</div>
							<form>
								<div className="login-password-field card-body">

									<InputField
										name="current_password"
										label="Current Password"
										required={true}
										placeholder="Enter here"
									/>

									<div className="login-password-field">

										<InputField
											name="new_password"
											label="New Password"
											required={true}
											placeholder="Enter here"
										/>
									</div>

									<div className="login-password-field">
										<InputField
											name="confirm_password"
											label="Confirm Password"
											required={true}
											placeholder="Enter here"
										/>
									</div>
								</div>

								<div className="card-footer border-top">
									<Button
										name="Submit"
										type="submit"
										className="btn btn-primary"
										label="Update Password"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div >
		</section >
	)
}

export default ChangePassword