import React from 'react';
import moment from 'moment';
import { _Object } from 'utils/interfaces';
import Loading from './loading';

const OpenCustomerDetails = ({ formik, loading }: _Object) => {
	return (
		<>
			<div className="gemstone-details-modal">
				<div
					className="modal fade"
					id="OpenCustomerDetails"
					aria-labelledby="OpenCustomerDetails"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-lg">
						<div className="modal-content">

							<div className="modal-header">
								<h5 className="mb-0">
									{formik?.values.role ? formik?.values?.role?.charAt(0)?.toUpperCase() + formik.values.role?.slice(1) : ''} Details
								</h5>
								<button
									type="button"
									className="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
								</button>
							</div>
							<div className="modal-body">
								{loading ? <div className="text-center"><Loading /></div> :
									<div className="row">
										<div className="col-4">
											{formik?.values.images?.path && <img src={formik?.values.images?.path} width={300} height={300} className="img-fluid" />}
										</div>
										<div className="col-8">
											{formik?.values.name &&
												<div className="name-customer">
													<h6>Name </h6>
													<p>{formik?.values.name}</p>
												</div>
											}
											{formik?.values.email &&
												<div className="name-customer">
													<h6>Email </h6>
													<p>{formik?.values.email}</p>
												</div>
											}
											{formik?.values.gender &&
												<div className="name-customer">
													<h6>Gender </h6>
													<p>{formik?.values.gender}</p>
												</div>
											}

											{formik?.values.phone_number &&
												<div className="name-customer">
													<h6>Phone number </h6>
													<p>{formik?.values.phone_number}</p>
												</div>
											}
											{formik?.values.birth_place &&
												<div className="name-customer">
													<h6>Birth Place</h6>
													<p>{formik?.values.birth_place}</p>
												</div>
											}

											{formik?.values.dob &&
												<div className="name-customer">
													<h6>Date of birth </h6>
													<p>{formik?.values.dob}</p>
												</div>
											}

											{formik?.values.status &&
												<div className="name-customer">
													<h6>Status </h6>
													<p>  {formik?.values.status ? formik?.values?.status?.charAt(0)?.toUpperCase() + formik?.values?.status?.slice(1) : ''}
													</p>
												</div>
											}

											{formik?.values.created_at &&
												<div className="create-date">
													<h6>Created date</h6> <p>{moment(formik?.values.created_at)?.utc()?.format('DD MMM, YYYY')}</p>
												</div>
											}

										</div>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div >
		</>
	);
};

export default OpenCustomerDetails;

