import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class EnquirieService extends CommonService {
	async getEnquires(params?: { [key: string]: boolean | string }) {
		return await this.get(`gems/enquiries/?${generateQueryParams(params)}`)
	}
	async getEnquiryDetails(id: string | undefined) {
		return await this.get(`gems/enquiries/${id}`)
	}

	async createEnquiry(params: { [key: string]: boolean | string }) {
		return await this.post('gems/enquiries', params)
	}

	async updateEnquiry(id: any, params: { [key: string]: boolean | string }) {
		return await this.put(`gems/enquiries/${id}`, params)
	}

	async postComment(id: any, params: { [key: string]: boolean | string }) {
		return await this.post(`gems/enquiries/${id}/comments`, params)
	}

	async deleteEnquiry(id: string) {
		return await this.delete(`gems/enquiries/${id}`)
	}
}

export const enquirieService = new EnquirieService();

