import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { _Object } from './interfaces'
import { routes } from './routes'
import { strings } from './strings'
import moment from 'moment'
export { routes, strings }
import { faArrowUp, faArrowDown, faRightLeft } from '@fortawesome/free-solid-svg-icons'

export const generateQueryParams = (args: any) => {
	let str = ''
	let replace = ''
	for (const key in args) {
		if (typeof (args[key]) === 'object') {
			for (const key1 in args[key]) {
				str += `&filters[${key1}]=${args[key][key1]}`
				replace = str?.slice(1)
			}
		} else {
			str += `&${key}=${args[key]}`
			replace = str?.slice(1)
		}
	}
	return replace
}

export const capitalizeFirstLetter = (name = '') => {
	const firstLetter = name && name?.charAt(0)?.toUpperCase()
	const restOfName = name?.slice(1)?.toLowerCase()
	const capitalizedUserName = firstLetter + restOfName
	return capitalizedUserName;
};

export const getAcronym = (inputString: string) => {
	if (inputString) {
		const words = inputString?.split(' ');
		const acronym = words.map(word => word?.charAt(0)?.toUpperCase()).join('');
		return acronym;
	}
};

export const dateFormat = (date: string) => {
	return moment(date).format('DD MMM, yyyy');
}

export const timeFormat12 = (time: string | Date) => {
	return moment(time).format('hh:mm A'); // 'A' adds AM/PM
};

export const closeModal = (selector = 'new-client') => {
	const closeButton = document.querySelectorAll(`#${selector} .btn-close`)
	if (closeButton[0] instanceof HTMLElement) {
		closeButton[0]?.click()
	}
}

export function truncateStringByWords(inputString: string, wordLimit = 20) {
	// Split the input string into an array of words
	const words = inputString?.split(' ');

	// Check if the number of words exceeds the specified word limit
	if (words?.length > wordLimit) {
		// If so, slice the array to the word limit and join it back into a string with an ellipsis
		return words?.slice(0, wordLimit)?.join(' ') + '...';
	}

	// If the word count is within the limit, return the original string
	return inputString;
}

export const handleFilters = (key: string, value: any, setState: any) => {
	if (key === 'filters') {
		setState((pre: _Object) => ({
			...pre,
			filters: {
				...pre.filters,
				[value?.key]: Array.isArray(value?.value) ? value?.value?.map((data: { [key: string]: string }) => { return data.value }) || '' : value?.value?.value || ''
			}
		}))
	} else {
		setState((pre: _Object) => ({
			...pre,
			page: 1,
			[key]: value
		}))
	}
}

export const getSortIcon = (sortKey: string) => {
	if (!sortKey) return <FontAwesomeIcon icon={faRightLeft} className="arrow-up-down" />;
	if (sortKey.includes('-')) return <FontAwesomeIcon icon={faArrowDown} />;
	if (!sortKey.includes('-')) return <FontAwesomeIcon icon={faArrowUp} />;
}