import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import PublicRoutes from 'routes/public';
import PrivateRoutes from 'routes/private';
import PublicLayout from 'views/layouts/public';
import PrivateLayout from 'views/layouts/private';

import { routes } from 'utils';
import { SessionState, _Object } from 'utils/interfaces';

const App = () => {
	const { isUserLoggedIn, loggedInUser } = useSelector((state: { [key: string]: SessionState }) => state.session);

	const roleBasedRoutes = (route: _Object) => {
		const role = loggedInUser?.role;

		if (role === 'admin') {
			return true;
		}

		if (role === 'gems_manager') {
			if (route?.path?.startsWith('/app/inquiries')) {
				return true
			} else {
				if ([
					'/app/gems',
					'/app/bookings',
					'/app/inquiries',
					'/app/profile'
				]?.includes(route.path)) { return true }
			}
		}

		if (role === 'customer') {
			if (route.path === '/app/customers' || route.path === '/app/profile') {
				return true;
			}
		}

		if (role === 'staff') {
			if (route.path === '/app/team' || route.path === '/app/profile') {
				return true;
			}
		}

		if (role === 'astrologer') {
			if (route.path === '/app/bookings' || route.path === '/app/profile') {
				return true;
			}
		}

		if (role === 'manager') {
			if ([
				'/app/dashboard',
				'/app/bookings',
				'/app/gems',
				'/app/team',
				'/app/inquiries',
				'/app/customers',
				'/app/profile'
			]?.includes(route.path)) {
				return true;
			}
		}

		if (role === 'receptionist') {
			if ([
				'/app/dashboard',
				'/app/bookings',
				'/app/team',
				'/app/inquiries',
				'/app/customers',
				'/app/profile'
			]?.includes(route.path)) {
				return true;
			}
		}

		return false;
	};

	return (
		<>
			<BrowserRouter>
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route path="/app" element={isUserLoggedIn ? <PrivateLayout /> : <Navigate to={routes.login} />} >
							<Route index element={<Navigate to={routes.dashboard} />} />

							{PrivateRoutes.map((route: _Object, i: number) => {
								const accessCheck = route?.path?.startsWith('/app/inquiries') ? true : roleBasedRoutes(route);
								const isInqurySinglePage = route?.path?.startsWith('/app/inquiries')
								if (accessCheck === true || (isInqurySinglePage && (loggedInUser?.role === 'manager' || loggedInUser?.role === 'gems_manager' || loggedInUser?.role === 'admin'))) {
									return (
										<Route
											key={i}
											path={route.path}
											element={<route.component />}
										/>
									);
								} else if (route.path === '/app/dashboard' && loggedInUser?.role === 'gems_manager') {
									return <Route key={i} path={route.path} element={<Navigate to="/app/gems" />} />
								} else if (route.path === '/app/dashboard' && loggedInUser?.role === 'astrologer') {
									return <Route key={i} path={route.path} element={<Navigate to="/app/bookings" />} />;
								} else if (route.path === '/app/dashboard' && (loggedInUser?.role === 'manager' || loggedInUser?.role === 'receptionist')) {
									return <Route key={i} path={route.path} element={<Navigate to="/app/dashboard" />} />;
								} else {
									return <Route key={i} path={route.path} element={<Navigate to={location.pathname} />} />;
								}
							})}

							<Route path="/app/*" element={<Navigate to={routes.dashboard} />} />
						</Route>

						{/* Public Routes */}
						<Route path="/" element={<PublicLayout />}>
							<Route index element={<Navigate to={routes.login} />} />

							{PublicRoutes.map((route, i) => (
								<Route
									key={i}
									path={route.path}
									element={<route.component />}
								/>
							))}

							<Route path="/*" element={<Navigate to={routes.login} />} />
						</Route>
					</Routes>
				</Suspense>
			</BrowserRouter>
		</>
	);
};

export default App;
