import { routes } from 'utils';
import { Dashboard, Booking, Customer, Gem, Inquiry, CustomerAddEdit, GemAddEdit, InquiryDetails, ChangePassword, Profile, Team } from 'views/pages';
import Chat from 'views/pages/dashboard/chat';
import Quotes from 'views/pages/dashboard/qutoe';
import Setting from 'views/pages/dashboard/setting';

const PrivateRoutes = [
	//Dashboard
	{ path: `${routes.dashboard}`, exact: true, name: 'dashboard', component: Dashboard },

	//Booking
	{ path: `${routes.booking}`, exact: true, name: 'bookings', component: Booking },

	//Customer
	{ path: `${routes.customer}`, exact: true, name: 'customers', component: Customer },
	{ path: `${routes.customer}/create`, exact: true, name: 'customer-create', component: CustomerAddEdit },
	{ path: `${routes.customer}/edit/:id`, exact: true, name: 'customer-dit', component: CustomerAddEdit },

	//Gem
	{ path: `${routes.gem}`, exact: true, name: 'gems', component: Gem },
	{ path: `${routes.gem}/create`, exact: true, name: 'gem-create', component: GemAddEdit },
	{ path: `${routes.gem}/edit/:id`, exact: true, name: 'gem-edit', component: GemAddEdit },

	//Quotes
	{ path: `${routes.qutoes}`, exact: true, name: 'qutoes', component: Quotes },
	{ path: `${routes.qutoes}/create`, exact: true, name: 'qutoes-create', component: GemAddEdit },
	{ path: `${routes.qutoes}/edit/:id`, exact: true, name: 'qutoes-edit', component: GemAddEdit },

	//Inquiry
	{ path: `${routes.inquiry}`, exact: true, name: 'inquiries', component: Inquiry },
	{ path: `${routes.inquiry}/:id`, exact: true, name: 'inquiry-edit', component: InquiryDetails },

	//Account
	{ path: `${routes.changePassword}`, exact: true, name: 'Change Password', component: ChangePassword },
	{ path: `${routes.profile}`, exact: true, name: 'Profile', component: Profile },
	{ path: `${routes.team}`, exact: true, name: 'team', component: Team },

	//setting
	{ path: `${routes.setting}`, exact: true, name: 'Setting', component: Setting },

	//chat
	{ path: `${routes.chat}`, exact: true, name: 'Chat', component: Chat }
]

export default PrivateRoutes;