import { generateQueryParams } from 'utils';
import CommonService from './common.service';

class BookingsService extends CommonService {
	async getBookings(params?: { [key: string]: boolean | string }) {
		return await this.get(`orders?${generateQueryParams(params)}`)
	}
	async getBookingDetails(id: string | undefined) {
		return await this.get(`orders/${id}`)
	}

	async createBookings(params: { [key: string]: boolean | string }) {
		return await this.post('orders', params)
	}

	async updateBookings(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`orders/${id}`, params)
	}

	async deleteBooking(id: string) {
		return await this.delete(`orders/${id}`)
	}

	async getFees() {
		return await this.get('consultation-fees')
	}
}

export const bookingsService = new BookingsService();

